
import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_BarangPenerimaanList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.RECEPTION.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_BarangPenerimaanSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.RECEPTION.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_BarangPenerimaanDetail = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.RECEPTION.GET, {kode: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PenerimaanBarangList = (kode, id_company, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.RECEPTION.BARANG_LIST, {kode: kode, id_company: id_company}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  post_BarangPenerimaanSave,
  get_BarangPenerimaanList,
  get_BarangPenerimaanDetail,
  get_PenerimaanBarangList
}